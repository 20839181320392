import React from "react"
import Layout from "../components/layout"
import Container from "../components/container";
import { Link } from "gatsby"

export default function Home() {
  
  
  return (
    <Layout>
      <Container>
        <p>This is a Gatsby test project with React</p>
      </Container>
    </Layout>
  )
}